<template>
  <div>
    <b-container fluid class="mt-7">
      <div>
        <b-card
          class="col-lg-8 col-sm-12"
        >
          <b-row>
            <b-col class="col-8">
              <h1>Utilisateurs</h1>
              <p>Liste des utilisateurs</p>
            </b-col>
            <b-col class="col-4 text-right" align-h="end">
                <b-form-checkbox v-model="showAction" name="check-button" @change="showActionColumn()" size="sm" switch>Edit</b-form-checkbox>
            </b-col>
          </b-row>
          <b-table
            :items="users"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :busy="isBusy"
            ref="usersTable"
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(actions)="data">
              <b-link @click="triggerEditUser(data.item)"><b-icon icon="pencil"></b-icon></b-link>
              <b-link @click="triggerDeleteUser(data.item)" class="text-warning pl-1"><b-icon icon="trash"></b-icon></b-link>
            </template>

          </b-table>
        </b-card>

      </div>
    </b-container>
    <b-modal
      id="modalUserForm"
      title="Edition d'un parcours"
      ref="user-form-modal"
      v-model="modalEditUserShow"
      @hidden="modalEditUserHiddenAction"
      size="xl"
      hide-footer
    >
      <b-form
        @submit.prevent="triggerSubmitForm(user)"
      >
        <b-row>
          <b-col class="col-6">
            <label for="inputUsername">Login</label>
            <b-form-input id="inputUsername" class="form-control" v-model="selectedUser.username" disabled></b-form-input>
          </b-col>
          <b-col class="col-6">
            <label for="inputEMail">email</label>
            <b-form-input id="inputEMail" class="form-control" v-model="selectedUser.email" disabled></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-6">
            <label for="inputFirstName">Prénom</label>
            <b-form-input id="inputFirstName" class="form-control" v-model="selectedUser.firstName" disabled></b-form-input>
          </b-col>
          <b-col>
            <label for="inputLastName">Nom</label>
            <b-form-input id="inputLastName" class="form-control" v-model="selectedUser.lastName" disabled></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Roles">
              <b-form-checkbox-group
                id="inputRoles"
                v-model="user.roles"
                :options="selectOptions"
                name="flavour-1"
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mr-2">
          <b-button @click="$bvModal.hide('modalUserForm')" class="btn btn-secondary mt-2" variant="secondary">Annuler</b-button>
          <b-button type="Submit" class="btn btn-primary mt-2" variant="primary">Mettre à jour</b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';;

export default {
  name: "AdminUsers",
  data() {
    return {
      fields: [
        {key: 'username', label:'Identifiant', Sortable:true},
        {key: 'firstName', label:'Prénom', Sortable:true},
        {key: 'lastName', label:'Nom', Sortable:true},
        {key: 'email', label:'eMail', Sortable:true},
        {key: 'roles', label:'Roles', Sortable:true,
        formatter : (data, key, item) => {
          let message = '';
          for(let key in data){
            key === '0' ? message = data[key] : message = message+', '+data[key]
          }
          return message;
        }},
      ],
      actionsField: [{key: 'actions', label: '', Sortable: false}],
      isBusy: false,
      sortBy: 'username',
      sortDesc: false,
      showAction:false,
      user: {
        roles:['ROLE_USER']
      },
      selectedUser:{
        username:'',
        firstName:'',
        lastName:'',
        email:''
      },
      selectedUserId:0,
      selectOptions:[
        { text:'User', value:'ROLE_USER'},
        { text:'Manager', value:'ROLE_MANAGER'},
        { text:'Admin', value:'ROLE_ADMIN'},
      ],
      modalEditUserShow:false
    }
  },
  mounted() {
    this.loadUsers()
  },
  computed:{
    ...mapGetters({
      users: 'users/users',
    })
  },
  methods: {
    ...mapActions({
      loadUsers: 'users/loadUsers',
      updateUser: 'users/updateUser',
      deleteUser: 'users/deleteUser',
    }),
    showActionColumn: function () {
      this.$nextTick(function () {
        this.showAction ? this.fields.push(this.actionsField) : this.fields.pop();
      })
    },
    modalEditUserHiddenAction: function () {
      this.clearForm()
    },
    clearForm : function () {
      this.user.roles = ["ROLE_USER"]
      this.selectedUser.username=''
      this.selectedUser.firstName=''
      this.selectedUser.lastName=''
      this.selectedUser.email=''
    },
    triggerEditUser : function (user) {
      this.user.roles = []
      for(let role of user.roles){
        this.user.roles.push(role)
      }
      this.selectedUser.id = user.id
      this.selectedUser.username = user.username
      this.selectedUser.firstName = user.firstName
      this.selectedUser.lastName = user.lastName
      this.selectedUser.email = user.email

      this.$refs['user-form-modal'].show();
    },
    triggerSubmitForm: function () {
      this.updateUser({user:this.user, username:this.selectedUser.username})
      .then((response) => {
        if(response === 200) {
          this.notif('success', 'Utilisateur mis à jour')
          this.$refs.usersTable.refresh()
          this.$refs['user-form-modal'].hide()
        }else{
          this.notif('warning', 'Erreur lors de la mise à jour de l\'utilisateur')
        }
      })
      .catch(()=>{
        this.notif('warning', 'Erreur lors de la mise à jour de l\'utilisateur')
      })
    },
    triggerDeleteUser : function (user) {
      this.deleteUser(user.username)
      .then((response)=>{
        if(response === 204){
          this.notif('success', 'Utilisateur supprimé');
          this.$refs.usersTable.refresh();
        }else{
          this.notif('warning', 'Erreur lors de la suppression de l\'utilisateur')
        }
      })
      .catch((error) => {
        this.notif('warning', 'Erreur lors de la suppression de l\'utilisateur')
      });
    },
    notif(status, message) {
      this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message, type: status})
    },
  }
}
</script>

<style scoped>

</style>
